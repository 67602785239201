<template>
  <div
    class="home-page"
    v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
  >
    <header>
      <el-col class="text" :span="8">
        <el-card shadow="hover"
          ><div class="textHeader">
            <span class="el-icon-s-claim"></span> 本月销售订单
          </div>
          <div class="textBody">{{ headerInfo.orderNum }}单</div></el-card
        >
      </el-col>
      <el-col class="text" :span="8">
        <el-card shadow="hover"
          ><div class="textHeader">
            <span class="el-icon-s-cooperation"></span> 本月新签合同
          </div>
          <div class="textBody">{{ headerInfo.contractNum }} 单</div></el-card
        >
      </el-col>
      <el-col class="text" :span="8">
        <el-card shadow="hover"
          ><div class="textHeader">
            <span class="el-icon-s-data"></span> 本月销售总金额
          </div>
          <div class="textBody">
            {{ headerInfo.totalMoney | formatNumber }} 元
          </div></el-card
        >
      </el-col>
      <el-col class="text" :span="8">
        <el-card shadow="hover"
          ><div class="textHeader">
            <span class="el-icon-s-custom"></span> 会员余额总额
          </div>
          <div class="textBody">
            {{ headerInfo.balance | formatNumber }} 元
          </div></el-card
        >
      </el-col>
    </header>
    <div id="myChart" style="width：30px;height:300px;margin-top:10%"></div>
    <div class="container">
      <div id="toPrice" style="width：30px;height:200px;margin-top:10%"></div>
      <div id="inPrice" style="width：30px;height:200px;margin-top:10%"></div>
    </div>
    <el-button
      type="primary"
      plain
      icon="el-icon-download"
      @click="goDownloadByPost"
      style="width: 20%; height: 50px"
    >
      本月订单Excel下载
    </el-button>
    <el-button
      type="primary"
      plain
      icon="el-icon-download"
      @click="goDownloadByYEAR"
      style="margin-left: 3%; width: 15%; height: 50px"
    >
      本年度Excel下载
    </el-button>
  </div>
</template>
<script>
import axios from "axios";
import Big from "big.js";
import secret from "@/utils/secret.js";
export default {
  data() {
    return {
      loading: true,
      headerInfo: {
        orderNum: 0,
        contractNum: 0,
        totalMoney: 0,
        balance: 0,
      },
      inventoryMoney: {
        stockMoney: [],
        sellMoney: [],
      },
      brokenMoney: {
        text: [],
        inPrice: [],
        outPrice: [],
      },
      saveFileName: "hdsdsd",
      userKey: "",
    };
  },
  created() {},
  mounted() {
    this.userKey = secret.Decrypt(localStorage.getItem("user_key"));
    const home = true;
    let userKey = this.userKey;
    axios
      .post(this.GLOBAL.api + "/home/review", { home, userKey })
      .then((res) => {
        if (res.data.statusCode === 200) {
          this.inventoryMoney.stockMoney.push(
            res.data.data.intotal[0].sum !== null
              ? `${+res.data.data.intotal[0].sum | FormData}`
              : 0
          );
          this.inventoryMoney.sellMoney.push(
            res.data.data.outTotal[0].sum !== null
              ? `${+res.data.data.outTotal[0].sum | FormData}`
              : 0
          );
          this.drawLine();
          res.data.monthInMoney.forEach((element, index) => {
            if (res.data.monthInMoney.length - index < 7) {
              this.brokenMoney.text.push(element.month);
              this.brokenMoney.inPrice.push(element.total);
            }
          });
          res.data.monthToMoney.forEach((element, index) => {
            if (res.data.monthToMoney.length - index < 7) {
              this.brokenMoney.outPrice.push(element.total);
            }
          });
          this.drawLineInPrice();
          this.loading = false;
        } else {
          this.$message({
            message: "获取数据失败，请重试！",
            type: "worning",
          });
        }
        function sum(arr) {
          var s = 0;
          arr.forEach(function (val) {
            s = +Big(val.priceTotal).plus(s);
          });
          return s;
        }
        this.headerInfo = {
          orderNum: res.data.nowMonthNum,
          contractNum: res.data.nowMonthContract,
          totalMoney: sum(res.data.saleTotal),
          balance: res.data.balance[0].sum,
        };
      });
    this.drawLineInPrice();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "库存金额",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: ["金额"],
        },
        series: [
          {
            name: "库存进价",
            type: "bar",
            data: this.inventoryMoney.stockMoney,
          },
          {
            name: "库存价值金额",
            type: "bar",
            data: this.inventoryMoney.sellMoney,
          },
        ],
      });
    },
    drawLineInPrice() {
      let myChart = this.$echarts.init(document.getElementById("toPrice"));

      myChart.setOption({
        title: {
          text: "店铺销售｜进货统计",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["销售金额", "进货金额"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.brokenMoney.text,
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "销售金额",
            type: "line",
            stack: "Total",
            data: this.brokenMoney.outPrice,
          },
          {
            name: "进货金额",
            type: "line",
            stack: "Total",
            data: this.brokenMoney.inPrice,
          },
        ],
      });
    },
    // 导出excel
    goDownloadByPost() {
      const derive = true;
      const monthOrder = true;
      let userKey = this.userKey;
      // let result = "";
      axios
        .post(
          this.GLOBAL.api + "/home/review",
          { derive, monthOrder, userKey },
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          let data = res.data;
          let url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocment.spreadsheetml.sheet",
            })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "本月订单.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
    goDownloadByYEAR() {
      const derive = true;
      let userKey = this.userKey;
      axios
        .post(
          this.GLOBAL.api + "/home/review",
          { derive, userKey },
          {
            responseType: "arraybuffer",
          }
        )
        .then((res) => {
          let data = res.data;
          let url = window.URL.createObjectURL(
            new Blob([data], {
              type: "application/vnd.openxmlformats-officedocment.spreadsheetml.sheet",
            })
          );
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.setAttribute("download", "本年度订单.xlsx");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
    },
  },
};
</script>
<style scoped>
.home-page {
  padding: 5%;
  width: 90%;
  height: 90%;
  background-color: #ffffff;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.text {
  width: 20%;
  /* margin-left: 10%; */
}
.textHeader {
  color: #fff;
  padding: 3% 0;
  text-align: center;
  background-color: #39393d;
}
.textBody {
  padding: 5%;
  text-align: center;
  background-color: #dcdfe6;
}
</style>